<template>
  <ContentSection>
    <Container indent="container-wide sm:container-tight">
      <div class="grid gap-16 md:grid-cols-2">
        <!-- CARD 1 -->
        <div
          v-for="(card, index) in data.icon_cards"
          :key="index"
          :class="[{ 'md:mt-32': index === 1 }, `${card.color_theme.value}-el`]"
        >
          <!-- ICON -->
          <div class="w-full">
            <AppImage
              v-if="card.icon"
              loading="lazy"
              class="mx-auto w-[40%] object-contain text-blue-500"
              :src="card.icon"
            />
          </div>
          <!-- TITLE -->
          <h2
            class="mt-5 max-w-title text-lg font-medium text-blue-500 green-el:text-green-500 pink-el:text-pink-500 sm:mt-12 sm:text-2xl"
          >
            {{ card.title }}
          </h2>
          <!-- TEXT -->
          <p
            class="max-w-text text-lg font-medium sm:text-2xl"
            v-html="card.text"
          />
          <!-- LINKS -->

          <div
            class="mt-6 text-blue-500 green-el:text-green-500 pink-el:text-pink-500 sm:mt-12 sm:space-y-4"
          >
            <div v-for="(link, i) in card.links" :key="i">
              <AppLinkWithIcon :to="link.link" class="text-sm sm:text-base">
                {{ link.link_title }}
              </AppLinkWithIcon>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </ContentSection>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
